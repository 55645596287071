<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">订单列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column prop="order_id" label="订单ID">
				</el-table-column>
				<el-table-column prop="order_no" label="订单号">
				</el-table-column>
				<el-table-column width='250' prop="user_id" label="下单用户">
					<template slot-scope="scope">
						<div class="d-flex m-0 p-0">
							<viewer>
								<img style="width: 45px;height: 45px;" :src="scope.row.user.avatarUrl">
							</viewer>
							<div class="m-0 p-0 ml-2">
								<div flex="dir:left cross:center">昵称：{{scope.row.user.nickName}}</div>
								<div flex="dir:left cross:center">ID：（{{scope.row.user.user_id}}）</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="days_num" label="充值天数"></el-table-column>
				<el-table-column prop="price" label="价格"></el-table-column>
				<el-table-column align="center" label="下单时间" width="180">
					<template slot-scope="scope">
						<span style="">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				search: {
					s: 'store/member.order/index',
					keyword: '',
					page: 1,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>

<style>

</style>
